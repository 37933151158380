function reloadOTBanner() {
  var otConsentSdk = document.getElementById('onetrust-consent-sdk');
  if (otConsentSdk) {
    otConsentSdk.remove();
  }
  if (window.OneTrust != null) {
    OneTrust.Init();
    setTimeout(function () {
      OneTrust.LoadBanner();
      var toggleDisplay = document.getElementsByClassName(
        'ot-sdk-show-settings',
      )[0];
      toggleDisplay.onclick = function (event) {
        event.stopImmediatePropagation();
        window.OneTrust.ToggleInfoDisplay();
      };
    }, 1000);
  }
}

function OptanonWrapper() {
  var lang = document.documentElement.lang;
  window.OneTrust.changeLanguage(lang);
  var host = document.location.origin;
  var cookielink = document.getElementById('cookielink');
  if (cookielink) {
    cookielink.href = host + '/#/terms-of-use';
    cookielink.addEventListener('click', reloadOTBanner, false);
  }
}
